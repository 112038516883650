import React, { useContext } from "react";
import Logo1 from "../../Assets/logo-1.png";
import Logo2 from "../../Assets/logo-2.png";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";

const TitleBar = ({ setSelectedTab }) => {
  const { isUserLoggedIn } = useContext(UserContext); // This should be replaced with your actual login logic
  const navigate = useNavigate();
  return (
    <nav
      className={`w-full  ${
        isUserLoggedIn ? " bg-customBlue " : "bg-customYellow"
      } px-4 py-4 md:px-4 text-white flex justify-between items-center `}
    >
      <a href="/">
        <div className="logo flex items-center space-x-4">
          <div className="rounded-full bg-white p-2">
            <img src={Logo1} alt="Company Logo" className="h-10 md:h-12" />
          </div>
          <img
            src={Logo2}
            alt="Company Logo"
            className="h-8 md:h-8 hidden md:flex"
          />
        </div>
      </a>

      <div>
        {/* Example conditional rendering for logged-in status */}
        {isUserLoggedIn ? (
          <div
            className="flex items-center justify-center rounded-full w-8 md:w-12 h-8 md:h-12 bg-white-800"
            onClick={() => {
              setSelectedTab("profile");
              navigate("/profile");
            }}
          >
            <i className="fa fa-user-circle text-4xl"></i>
          </div>
        ) : (
          <>
            <div className="flex space-x-4">
              <div
                className="bg-white  text-customBlue px-8 py-3  rounded-full font-bold"
                onClick={() => navigate("/login")}
              >
                Sign In
              </div>
              <div
                className=" text-white  bg-customBlue px-8 py-3 rounded-full hidden md:flex font-bold "
                onClick={() => navigate("/signup")}
              >
                Sign Up
              </div>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default TitleBar;
