import React from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"; // Importing quote icons from react-icons
import { quotes } from "./Quotes";
const QuoteOfTheDay = () => {
  const quoteData = quotes[Math.floor(Math.random() * quotes.length)];

  return (
    <div className="flex flex-col item-center p-4 rounded-lg shadow-lg m-4 bg-gradient-to-b from-gray-300 to-gray-100 text-gray-800">
      <div>
        <FaQuoteLeft className=" opacity-50 text-sm md:text-lg " />
      </div>
      <div>
        <p className="text-center text-sm md:text-lg  font-medium my-2 px-4">
          {quoteData.quote}
        </p>
      </div>
      <div className="flex justify-end">
        <FaQuoteRight className=" opacity-50 text-sm md:text-lg " />
      </div>
      <div>
        <p className=" text-center mt-2 italic text-xs md:text-sm ">
          — {quoteData.book}
        </p>
      </div>
    </div>
  );
};

export default QuoteOfTheDay;
