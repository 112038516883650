import { LoginResponse } from "../Serializers/SerializerModels";
import { fetchGetData } from "../Utilities/FetchUtils";

export const handleToken = async (data) => {
  localStorage.setItem("access_token", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);
};

export const handleLogout = () => {
  localStorage.clear();
};
export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};
const getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
};

export const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    try {
      const response = await fetch("http://192.168.1.2:8000/user/get_access", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${refreshToken}`,
        },
      });
      if (!response.ok) {
        return null;
      }
      const data = await response.json();
      handleToken(new LoginResponse(data.results));
      return true;
    } catch (error) {
      handleLogout();
      window.location.replace("/login");
      return null;
    }
  }
};

export const verifyLogin = async () => {
  if (getAccessToken() && getRefreshToken()) {
    const { response, error } = await fetchGetData("user/get_current_user");
    if (response) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
