import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import BooksComponent from "../Books/BooksComponent";

import { handleSearch } from "./SearchUtils";
import Loading from "../Common/Loading";

const SearchComponent = ({ setSelectedTab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [searchTerm, setSearchTerm] = useState(queryParams.query || "");
  const [booksDetails, setBooksDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initialSearch = queryParams.query;
    if (initialSearch) {
      handleSearch({
        searchTerm: initialSearch,
        setBooksDetails,
        setIsLoading,
        setError,
      });
    }
  }, [location.search]);

  useEffect(() => {
    setSelectedTab("search");
  }, [setSelectedTab]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch({
        searchTerm,
        setBooksDetails,
        setIsLoading,
        setError,
      });
      navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
    }
  };

  return (
    <div className={`h-[100%] bg-gray-50 overflow-y-auto w-full md:rounded-tl-2xl`}>
      <div
        className={`flex flex-col items-center w-full h-full overflow-auto p-4 bg-gray-100 ${!booksDetails.length && !isLoading && !error ? "justify-center" : ""
          }`}
      >
        <div className={`w-full max-w-lg flex items-center border rounded-lg bg-white shadow-sm mb-2 `}>
          <input
            className="flex-grow p-2 text-lg"
            type="text"
            placeholder="Search books by name/author/genre"
            value={searchTerm}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <i
            className="fa-solid fa-search text-gray-500 p-4"
            onClick={() => {
              handleSearch({
                searchTerm,
                setBooksDetails,
                setIsLoading,
                setError,
              });
              navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
            }}
          />
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {error && (
              <div className="w-full max-w-lg flex items-center p-3 justify-center h-full">
                <p className="text-gray-500">{error}</p>
              </div>
            )}
            {booksDetails && booksDetails.length > 0 ? (
              <BooksComponent booksDetails={booksDetails} />
            ) : (
              <div className="w-full max-w-lg flex items-center p-3 justify-center h-full">
                <p className="text-gray-500">
                  { !error && "Start your search by typing a book name, author, or genre."}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchComponent;
