import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BookDetailComponent from "./BookDetailComponent";
import Loading from "../Common/Loading";

const BooksScrollable = ({ books, sectionTitle, isLoading }) => {
  const [openBook, setOpenBook] = React.useState(null);

  const settings = {
    arrows: true,
    focusOnSelect: true,
    swipeToSlide: true,
    touchMove: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 4, slidesToScroll: 1 },
      },
      {
        breakpoint: 800,
        settings: { slidesToShow: 3, slidesToScroll: 1 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
    ],
  };

  return (
    <div>
      <h2 className="text-2xl md:text-3xl font-bold ">{sectionTitle}</h2>
      {isLoading ? (
        <Loading />
      ) : (
        <Slider {...settings}>
          {books.map((book) => (
            <div
            key={book.id}
            className="p-2 focus:outline-none h-full mb-2"
            onClick={() => setOpenBook(book)}
            tabIndex={0}
            role="button"
            aria-label={`View details for ${book.title}`}
            onKeyDown={(event) => event.key === "Enter" && setOpenBook(book)}
          >
            <div
              className="book-item border rounded-lg shadow-lg flex flex-col hover:shadow-xl cursor-pointer transition-shadow"
              // Set responsive width and height for the cards
              // sm: for small screens, md: for medium, lg: for large, xl: for extra large
              style={{ height: 'auto' }}
            >
              <div className="flex justify-center p-2 md:p-4 mt-2">
                <img
                  src={book.image_url}
                  alt={book.title}
                  className="w-full h-48 sm:h-56 md:h-64 lg:h-72 xl:h-80 rounded-lg object-cover"
                  // Ensure the image fits its container without distortion
                />
              </div>
              <div className="p-2 flex-1">
                <div className="text-md md:text-lg font-bold truncate">{book.title}</div>
                <p className="text-xs md:text-sm text-gray-700 truncate">{book.author}</p>
              </div>
            </div>
          </div>
          
          ))}
        </Slider>
      )}

      {openBook && (
        <BookDetailComponent
          book={openBook}
          onClose={() => setOpenBook(null)}
        />
      )}
    </div>
  );
};

export default BooksScrollable;
