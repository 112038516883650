import InstaLogo from "../../Assets/Instagram_Glyph_White.png";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
// import sideImage from "../Assets/illustration.svg";
import image_hero1 from "../../Assets/Image Hero.png";
import image_hero2 from "../../Assets/Image Hero-1.png";
import image_hero3 from "../../Assets/Image Hero-2.png";
import image_hero4 from "../../Assets/Image Hero-3.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MBCard1 from "./MBCard1";
import WaitlistModal from "../Waitlist/WaitlistModal";
import { verifyLogin } from "../UserAuthentication/AuthUtils";
import Loading from "../Common/Loading";
import { ToastContainer } from "react-toastify";

const cardData = [
  {
    title: "Effortless",
    title2: "Book Rentals",
    description:
      "Browse. Select. Rent. Our streamlined process makes it simple to access a vast library of titles, tailored to your reading preferences.",
    img: image_hero1,
    reverse: false,
  },
  {
    title: "Engage",
    title2: "with character",
    description:
      "What would Jane Eyre say about modern society? How would Sherlock Holmes solve today's mysteries? Chat directly with your favourite characters and explore new dimensions of storytelling.",
    img: image_hero2,
    reverse: true,
  },
  {
    title: "Curated",
    title2: "Recommendations",
    description:
      "Not sure what to read next? Let our intelligent algorithm suggest books that match your interests, helping you uncover hidden gems and timeless classics.",
    img: image_hero3,
    reverse: false,
  },
  {
    title: "Reading ",
    title2: "Progress Tracker",
    description:
      "Monitor your journey through every book. Set reading goals, track your progress, and gain insights into your reading habits—all in one place.",
    img: image_hero4,
    reverse: true,
  },
];
const LandingPageComponent = () => {
  const [inviteCode, setInviteCode] = useState("");
  const [showWaitListModal, setShowWaitListModal] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const userLoggedIn = async () => {
      setIsLoading(true);
      try {
        const loggedIn = await verifyLogin();
        if (loggedIn) {
          navigate("/home");
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    userLoggedIn();
  }, []);

  const handleClick = () => {
    if (!inviteCode) {
    } else {
      navigate("/signup?invite_code=" + inviteCode);
    }
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>  
        <ToastContainer/>
          <div className=" bg-customYellow ">
            <WaitlistModal
              show={showWaitListModal}
              setShowWaitListModal={setShowWaitListModal}
            />
            <div className="md:hidden">
              <MBCard1
                inviteCode={inviteCode}
                setInviteCode={setInviteCode}
                handleClick={handleClick}
              />
            </div>
            <div className="hidden md:block">
              <Card1
                inviteCode={inviteCode}
                setInviteCode={setInviteCode}
                handleClick={handleClick}
              />
            </div>
            {cardData.map((x) => (
              <Card2 cardData={x} />
            ))}
            <Card3 setShowWaitListModal={setShowWaitListModal} />
          </div>
        </>
      )}
    </>
  );
};

export default LandingPageComponent;
